
import ddc from '@/http/ddc'
// ddc 通用接口文件
export * from './open-account'

export const searchAccountSourceId = (name) => {
  return ddc
    .get(`http://api-ddc-wscn.xuangubao.cn/extract/arthas/sources?name=${name}`)
}

// ddc icon路由管理
export const fetchAppRoute = (params) => {
  return ddc.get(`/analytics/routing/control`, { params })
}
export const updateAppRoute = (body) => {
  return ddc.post(`/analytics/routing/control`, body)
}
export const deleteAppRoute = (body) => {
  return ddc.delete(`/analytics/routing/control`, { data: body })
}
export const fetchSubTypeName = (params) => {
  return ddc.get(`/analytics/routing/sub_type`, { params })
}

// 获取龙虎榜机构热门文章模板数据 timestamp 时间戳秒
export const getJigouChampionList = (params) => {
  return ddc.get('/dragon_tiger/hot_article', {
    params
  })
}
/**
 * 获取龙虎榜个股详情页数据
 * @param {String} prod_code 股票代码
 * @param {String} day_str 日期
 */
export const getRankChampionList = (params) => {
  return ddc.get('/dragon_tiger/stock', {
    params
  })
}
// timestamp 时间戳秒
export const getWellKnowChampionList = (params) => {
  return ddc.get('/dragon_tiger/well_known_article', {
    params
  })
}