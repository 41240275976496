<template>
  <div class="page-common">
    <div class="page-params">
      <el-input
        clearable
        class="param-item w200"
        placeholder="搜icon名称"
        v-model="params.name"
        @clear="initTableData"
      ></el-input>
      <el-select
        clearable
        class="param-item w200"
        filterable
        allow-create
        placeholder="搜icon分类"
        v-model="params.category"
        @change="initTableData"
      >
        <el-option v-for="(sub, key) in subTypeMap" :key="key" :label="sub" :value="key"></el-option>
      </el-select>
      <el-button class="param-item" type="primary" icon="el-icon-search" @click="initTableData">搜索</el-button>
      <el-button class="param-item" icon="el-icon-sort" @click="sortVisible = true">排序</el-button>
      <el-button type="primary" class="param-item" icon="el-icon-plus" @click="handleAddIcon">添加</el-button>
    </div>
    <el-table
      ref="table"
      size="medium"
      :data="tableData"
      v-loading="tableLoading"
      element-loading-spinner="el-icon-loading"
    >
      <el-table-column prop="id" label="ID" width="80" align="center" />
      <el-table-column label="分类" align="center">
        <template #default="{row}">{{subTypeMap[row.category]}}</template>
      </el-table-column>
      <el-table-column label="名称" align="center">
        <template #default="{row}">
          <div v-if="row.app && row.app.name">
            <span>{{row.app.name}}</span>
            <el-tag v-if="row.sticky_mark" type="success" style="margin-left: 6px">置顶</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="app.image" label="图片" align="center">
        <template #default="{row}">
          <div v-if="row.app && row.app.image">
            <img style="max-width: 200px" :src="row.app.image" alt="xgb" />
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="app.route" label="路由" align="center" />
      <el-table-column align="center" label="操作" width="260">
        <template #default="{ row, $index }">
          <el-button size="mini" @click="handleEditIcon(row, $index)">编辑</el-button>
          <el-popconfirm title="确认删除操作？" @confirm="handleDeleteIcon(row, 1)">
            <template #reference>
              <el-button size="mini" type="danger">删除</el-button>
            </template>
          </el-popconfirm>
          <el-button
            size="mini"
            v-if="!row.sticky_mark"
            @click="handleStickyIcon(row, $index, true)"
          >设为置顶</el-button>
          <el-button
            type="warning"
            size="mini"
            v-else
            @click="handleStickyIcon(row, $index, false)"
          >取消置顶</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      title="新增/编辑icon"
      custom-class="medium-modal"
      :close-on-click-modal="false"
      v-model="editingVisible"
    >
      <el-form
        ref="icon"
        label-width="120px"
        label-position="left"
        :rules="rules_icon"
        :model="editingIcon"
      >
        <el-form-item label="名称" prop="app.name">
          <el-input clearable placeholder="请填写" v-model="editingIcon.app.name"></el-input>
        </el-form-item>
        <el-form-item label="选择路由">
          <SoloBaseSelect
            style="width: 100%"
            placeholder="请选择路由"
            :searchMethod="searchAppRoute"
            v-model="bindRoute"
            :keymap="{ label: 'name', value: 'id' }"
            @get-option="updateIconByRoute"
          />
        </el-form-item>
        <el-form-item label="resource_id" prop="app.resource_id">
          <el-input type="number" v-model="editingIcon.app.resource_id"></el-input>
        </el-form-item>
        <el-form-item
          label="resource_type"
          prop="app.resource_type"
        >{{editingIcon.app.resource_type}}</el-form-item>
        <el-form-item label="路由" prop="app.host">
          <el-input v-model.trim="editingIcon.app.host"></el-input>
        </el-form-item>
        <el-form-item label="路由" prop="app.route">
          <el-input v-model.trim="editingIcon.app.route"></el-input>
          <span class="mark">请根据正则及resource_id填写路由</span>
        </el-form-item>
        <el-form-item label="分类" prop="category">
          <el-select filterable allow-create v-model="editingIcon.category" placeholder="请选择">
            <el-option v-for="(sub, key) in subTypeMap" :key="key" :label="sub" :value="key"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="图片" prop="app.image">
          <UploadQiniu v-model="editingIcon.app.image" />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="iconConfirm">确 定</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog
      title="icon调序"
      custom-class="medium-modal"
      :close-on-click-modal="false"
      v-model="sortVisible"
    >
      <template v-for="(arr, cate) in sortArray" :key="cate">
        <h1 class="sort-line">{{cate}}</h1>
        <Draggable v-model="sortArray[cate]" item-key="id">
          <template #item="{ element }">
            <el-tag class="drag-tag">{{element.id}} {{ element?.app?.name }}</el-tag>
          </template>
        </Draggable>
      </template>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="sortConfirm">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import cloneDeep from 'lodash/cloneDeep'
import UploadQiniu from '@/components/base/upload-qiniu.vue'
import Draggable from 'vuedraggable'
import SoloBaseSelect from '@/components/selectors/solo-selector'
import { fetchAppRoute } from '@/domains/base-ddc'
import { fetchAppIcon, addAppIcon, updateAppIcon, deleteAppIcon } from '@/domains/base-baoer'

const params = {
  limit: 50,
  name: '',
  category: ''
}
const mockIcon = {
  app: {
    image: '',
    name: '',
    resource_id: '',
    resource_type: '',
    route: '',
    host: 'https://xuangubao.cn'
  },
  category: '',
  created_at: 0,
  id: 0,
  order: 0,
  route_object: '',
  sticky_order: 0,
  updated_at: 0
}
const subTypeMap = {
  firm: '王牌栏目',
  stock_screener: '选股功能',
  // subject: '话题',
  streamer: '热门直播间',
  plate: '热门板块',
  course: '热门课程',
  stark_account: '头部大咖',
  // other: '其他'
}
const rules_icon = {
  'app.name': [{ required: true, message: '必填项' }],
  'app.resource_type': [{ required: true, message: '必填项' }],
  'app.route': [{ required: true, message: '必填项' }],
  'app.host': [{ required: true, message: '必填项' }],
  category: [{ required: true, message: '必填项' }]
}

function searchAppRoute(query, ctx) {
  ctx.loading.value = true
  fetchAppRoute({ name: query, limit: 20 })
    .then((res) => {
      if (res.items && res.items.length) {
        ctx.options.value = res.items.map((it) => {
          it.name = `${it.name} ${it.type} ${it.route}`
          return it
        })
        return
      }
    })
    .finally(() => {
      ctx.loading.value = false
    })
}

function initStickyMark(iconData) {
  // sticky_order > 0 即为有置顶值
  const temp = iconData
    .filter((v) => v.sticky_order > 0)
    .map((v) => {
      return v.sticky_order
    })
    .sort((L, R) => R - L)
    .slice(0, 9)
  const sticky_min = temp[temp.length - 1] || 0
  iconData.forEach((icon) => {
    if (sticky_min && icon.sticky_order >= sticky_min) {
      icon.sticky_mark = true
    }
  })
  iconData.sort((L, R) => R.sticky_order - L.sticky_order)
}

export default {
  name: 'AppIconSetting',
  components: { UploadQiniu, SoloBaseSelect, Draggable },
  setup(props) {
    return { rules_icon, subTypeMap, searchAppRoute }
  },
  data() {
    return {
      params,
      tableData: [],
      tableLoading: false,
      editingVisible: false,
      editingIcon: cloneDeep(mockIcon),
      bindRoute: '',
      sortVisible: false,
      sortArray: {}
    }
  },
  mounted() {
    this.initTableData(true)
  },
  methods: {
    initTableData(is_init) {
      if (is_init) this.tableLoading = true
      fetchAppIcon(this.params)
        .then((res) => {
          if (res.items && res.items.length) {
            initStickyMark(res.items)
            this.tableData = res.items
            this.initAllSortType(cloneDeep(res.items))
          } else {
            this.tableData = []
          }
        })
        .finally(() => {
          this.tableLoading = false
        })
    },
    handleAddIcon() {
      const formref = this.$refs.icon
      if (formref) this.$nextTick(formref.clearValidate)
      this.editingIcon = cloneDeep(mockIcon)
      this.bindRoute = ''
      this.editingVisible = true
    },
    handleEditIcon(row, index) {
      console.log('handleEditIcon', row.id, index)
      this.editingIcon = cloneDeep(row)
      this.bindRoute = ''
      this.editingVisible = true
    },
    handleDeleteIcon(row, operate) {
      console.log('handleDeleteIcon', row.id)
      deleteAppIcon(row.id)
        .then(() => {
          this.$message.success('操作成功')
          this.initTableData()
        })
        .catch((err) => {
          this.$message.error(JSON.stringify(err))
        })
    },
    updateIconByRoute(route_op) {
      console.log(route_op)
      const { id, route, type, param } = route_op
      this.editingIcon.app.resource_type = type
    },
    iconConfirm() {
      const formref = this.$refs.icon
      formref.validate((ok) => {
        if (!ok) return
        const submitBody = this.editingIcon
        submitBody.app.resource_id = String(submitBody.app.resource_id)
        console.log(JSON.stringify(submitBody, null, 2))
        if (!submitBody.id) {
          addAppIcon({ item: submitBody })
            .then(() => {
              this.$message.success('已创建')
              this.editingVisible = false
              this.initTableData()
            })
            .catch((err) => {
              this.$message.error(JSON.stringify(err))
            })
          return
        }
        updateAppIcon({ items: [submitBody] })
          .then(() => {
            this.$message.success('已保存')
            this.editingVisible = false
            this.initTableData()
          })
          .catch((err) => {
            this.$message.error(JSON.stringify(err))
          })
      })
    },
    initAllSortType(icondata) {
      this.sortArray = {}
      const sortArray = this.sortArray
      icondata.forEach((icon) => {
        if (sortArray[icon.category]) {
          sortArray[icon.category].push(icon)
        } else {
          sortArray[icon.category] = [icon]
        }
      })
      Object.keys(sortArray).forEach((cate) => {
        if (sortArray[cate].length > 1) {
          sortArray[cate].sort((L, R) => L.order - R.order)
        }
      })
    },
    handleStickyIcon(row, index, tobe) {
      let sticky_value = 0
      this.tableData.forEach((it) => {
        if (it.sticky_order >= sticky_value) {
          sticky_value = it.sticky_order + 1
        }
      })
      if (tobe) {
        this.tableData[index].sticky_order = sticky_value
      } else {
        this.tableData[index].sticky_order = -1
      }
      const err_msg = tobe ? '已置顶' : '已取消置顶'
      updateAppIcon({ items: this.tableData })
        .then(() => {
          this.$message.success(err_msg)
          this.initTableData()
        })
        .catch((err) => {
          this.$message.error(JSON.stringify(err))
        })
    },
    sortConfirm() {
      const submitBody = []
      const sortArray = this.sortArray
      Object.keys(sortArray).forEach((cate) => {
        if (sortArray[cate].length > 1) {
          sortArray[cate].forEach((it, idx) => {
            it.order = idx + 1
          })
        }
        submitBody.push(...sortArray[cate])
      })
      updateAppIcon({ items: submitBody })
        .then(() => {
          this.$message.success('已保存')
          this.sortVisible = false
          this.initTableData()
        })
        .catch((err) => {
          this.$message.error(JSON.stringify(err))
        })
    }
  }
}
</script>
<style lang="scss">
.sort-line {
  font-size: 16px;
  font-weight: 600;
  margin-top: 16px;
  padding-left: 10px;
}
</style>