<template>
  <div class="page-common">
    <div class="page-params">
      <el-input
        clearable
        class="param-item w200"
        placeholder="搜页面名称"
        v-model="params.name"
        @clear="initTableData"
      ></el-input>
      <el-input
        clearable
        class="param-item w200"
        placeholder="搜子类型名称"
        v-model="params.sub_category"
        @clear="initTableData"
      ></el-input>
      <el-input
        clearable
        class="param-item w200"
        placeholder="搜路由"
        v-model="params.route"
        @clear="initTableData"
      ></el-input>
      <el-button class="param-item" type="primary" icon="el-icon-search" @click="initTableData">搜索</el-button>
      <el-button type="primary" class="param-item" icon="el-icon-plus" @click="handleAddRoute">添加</el-button>
    </div>
    <el-table
      size="medium"
      ref="table"
      :data="tableData"
      v-loading="tableLoading"
      element-loading-spinner="el-icon-loading"
    >
      <el-table-column prop="id" label="ID" width="80" align="center" />
      <el-table-column prop="category" label="类型" align="center" />
      <el-table-column label="子类型" align="center">
        <template #default="{row}">
          <div v-if="row.sub_category">
            {{ row.sub_category.name }} &nbsp;
            {{ row.sub_category.id }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="页面名称" align="center" />
      <el-table-column prop="route" label="路由" align="center" />
      <el-table-column prop="tip" label="提示" align="center" />
      <el-table-column align="center" label="操作">
        <template #default="{ row, $index }">
          <el-button size="mini" @click="handleEditRoute(row, $index)">编辑</el-button>
          <el-popconfirm title="确认删除操作？" @confirm="handleDeleteRoute(row, 1)">
            <template #reference>
              <el-button size="mini" type="danger">删除</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      title="新增/编辑路由"
      custom-class="medium-modal"
      :close-on-click-modal="false"
      v-model="editingVisible"
    >
      <el-form
        ref="route"
        label-width="120px"
        label-position="left"
        :rules="rules_route"
        :model="editingRoute"
      >
        <el-form-item label="路由类型" prop="category">
          <el-select allow-create v-model="editingRoute.category" placeholder="请选择">
            <el-option label="内容" value="content"></el-option>
            <el-option label="功能" value="function"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="子类型" prop="type">
          <el-select filterable allow-create v-model="editingRoute.type" placeholder="请选择">
            <el-option v-for="(sub, key) in subTypeMap" :key="key" :label="sub" :value="key"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="子类型名称" prop="sub_category.name">
          <el-input clearable placeholder="请填写" v-model="editingRoute.sub_category.name"></el-input>
        </el-form-item>
        <el-form-item label="页面名称" prop="name">
          <el-input placeholder="请填写" v-model.trim="editingRoute.name"></el-input>
        </el-form-item>
        <el-form-item label="路由" prop="route">
          <el-input placeholder="请填写" v-model.trim="editingRoute.route"></el-input>
          <span class="mark">填写正则表达式</span>
        </el-form-item>
        <el-form-item label="参数" prop="param">
          <el-input placeholder="请填写" type="number" v-model.number="editingRoute.param"></el-input>
          <span class="mark">若正则中存在分组匹配ID 指定是第几个分组</span>
        </el-form-item>
        <el-form-item label="备注" prop="tip">
          <el-input placeholder="请填写" v-model.trim="editingRoute.tip"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="routeConfirm">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import cloneDeep from 'lodash/cloneDeep'
// import { ElMessage as message } from 'element-plus'
import { fetchAppRoute, deleteAppRoute, updateAppRoute, fetchSubTypeName } from '@/domains/base-ddc'
const params = {
  limit: 10000,
  name: '',
  route: '',
  sub_category: ''
}
const mockRoute = {
  category: '',
  id: 0,
  name: '',
  param: 0,
  route: '',
  sub_category: {
    id: 0,
    name: ''
  },
  tip: '',
  type: ''
}
const subTypeMap = {
  firm: '王牌栏目',
  stock_screener: '选股功能',
  // subject: '话题',
  streamer: '热门直播间',
  plate: '热门板块',
  course: '热门课程',
  stark_account: '头部大咖'
  // other: '其他'
}
const rules_route = {
  name: [{ required: true, message: '必填项' }],
  'sub_category.name': [{ required: true, message: '必填项' }],
  type: [{ required: true, message: '必填项' }],
  route: [{ required: true, message: '必填项' }],
  category: [{ required: true, message: '必填项' }]
  // param: [{ type: 'number', min: 1, message: '请填写参数' }]
}
export default {
  name: 'AppRouteSetting',
  setup(props) {
    return { rules_route, subTypeMap }
  },
  data() {
    return {
      params,
      tableData: [],
      tableLoading: false,
      editingVisible: false,
      subTypeName: {},
      editingRoute: cloneDeep(mockRoute)
    }
  },
  mounted() {
    this.initTableData(true)
    // this.initSubTypeName()
  },
  methods: {
    initSubTypeName() {
      fetchSubTypeName().then((res) => {
        if (res.items && res.items.length) {
          const temp = {}
          res.items.forEach((sub) => {
            temp[sub.id] = sub.name
          })
          this.subTypeName = temp
        }
      })
    },
    initTableData(is_init) {
      if (is_init) this.tableLoading = true
      fetchAppRoute(this.params)
        .then((res) => {
          if (res.items && res.items.length) {
            this.tableData = res.items
          } else {
            this.tableData = []
          }
        })
        .finally(() => {
          this.tableLoading = false
        })
    },
    handleAddRoute() {
      const formref = this.$refs.route
      if (formref) this.$nextTick(formref.clearValidate)
      this.editingRoute = cloneDeep(mockRoute)
      this.editingVisible = true
    },
    handleEditRoute(row, index) {
      console.log('handleEditRoute', row.id, index)
      this.editingRoute = cloneDeep(row)
      this.editingVisible = true
    },
    handleDeleteRoute(row, operate) {
      console.log('handleDeleteRoute', row.id)
      deleteAppRoute({
        ids: [row.id]
      })
        .then(() => {
          this.$message.success('操作成功')
          this.initTableData()
        })
        .catch((err) => {
          this.$message.error(JSON.stringify(err))
        })
    },
    routeConfirm() {
      const formref = this.$refs.route
      formref.validate((ok) => {
        if (!ok) return
        const submitBody = this.editingRoute
        submitBody.param = Number(submitBody.param)
        const err_msg = submitBody.id ? '已保存' : '已创建'
        console.log(JSON.stringify(submitBody, null, 2))
        updateAppRoute(submitBody)
          .then(() => {
            this.$message.success(err_msg)
            this.editingVisible = false
            this.initTableData()
          })
          .catch((err) => {
            this.$message.error(JSON.stringify(err))
          })
      })
    }
  }
}
</script>
<style lang="scss">
</style>