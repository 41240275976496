
import AppRoute from '@/views/settings/app-route.vue'
import AppIcon from '@/views/settings/app-icon.vue'

export default {
  name: 'OpenAccount',
  components: {
    AppRoute,
    AppIcon
  },
  data() {
    return {
      cuttentTab: 'route'
    }
  }
}
