<template>
  <div class="page-open-account">
    <el-tabs v-model="cuttentTab">
      <el-tab-pane name="route" label="路由管理">
        <AppRoute />
      </el-tab-pane>
      <el-tab-pane name="icon" label="icon管理">
        <AppIcon />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script lang="ts">
import AppRoute from '@/views/settings/app-route.vue'
import AppIcon from '@/views/settings/app-icon.vue'

export default {
  name: 'OpenAccount',
  components: {
    AppRoute,
    AppIcon
  },
  data() {
    return {
      cuttentTab: 'route'
    }
  }
}
</script>
<style lang="scss" scoped>
.page-open-account {
  position: relative;
}
</style>
