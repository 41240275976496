import ddc from '@/http/ddc'
// 开户2.0相关 券商、banner
export const fetchBrokerList = (params) => {
  return ddc.get('/admin/misc/open_account_brokerage/list', { params })
}
export const updateBrokerItem = (body) => {
  return ddc.post('/admin/misc/open_account_brokerage/upsert', body)
}
export const deleteBrokerItem = (body) => {
  return ddc.post('/admin/misc/open_account_brokerage/delete', body)
}
export const fetchBrokerSort = (params) => {
  return ddc.get('/admin/misc/open_account_brokerage/order', { params })
}
export const updateBrokerSort = (body) => {
  return ddc.post('/admin/misc/open_account_brokerage/order', body)
}
export const fetchBannerList = (params) => {
  return ddc.get('/admin/misc/open_account_banner/list', { params })
}
export const updateBannerItem = (body) => {
  return ddc.post('/admin/misc/open_account_banner/upsert', body)
}
export const deleteBannerItem = (body) => {
  return ddc.post('/admin/misc/open_account_banner/delete', body)
}
export const updateBannerSort = (body) => {
  return ddc.post('/admin/misc/open_account_banner/order', body)
}